.customer-profile-image {
  object-fit: contain;
  width: 100%;
  height: 50px;
}
.customer-wrapper {
  padding: 20px;
}
.action-icons button{
  margin:2px 5px;
 }